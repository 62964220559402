import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import EventsCard from '../components/Events/EventsCard'
import { useIntl } from 'react-intl'
import ComingSoon from './coming-soon'

const Events = () => {
    const intl = useIntl()
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle={intl.formatMessage({ id: "Event" })}
                homePageText={intl.formatMessage({ id: "home" })} 
                homePageUrl="/" 
                activePageText={intl.formatMessage({ id: "Event" })} 
            />
            <ComingSoon/>
            <Footer />
        </Layout>
    );
}

export default Events